import type { Hit } from '@algolia/client-search';

import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { trackEvent } from 'utils/analytics/track/trackEvent';

export function searchTrackDisplayedListings(
  hits: Hit<SearchAlgoliaHit>[],
  searchTrackingData: Record<string, unknown>,
) {
  const listingIds = hits ? hits.map((h) => h.objectID) : [];
  const rankingInfo = hits
    ? hits.map(({ _rankingInfo, objectID }) => ({
        rankingInfo: _rankingInfo,
        objectID,
      }))
    : [];

  trackEvent('Displayed Listings', {
    listing_ids: listingIds,
    ranking_info: rankingInfo,
    page_type: 'Searches for Listings',
    ...searchTrackingData,
  });
}
