import invariant from 'invariant';
import queryString from 'query-string';

import { searchConvertFiltersByNameToFiltersById } from 'modules/search/helpers/searchConvertFiltersByNameToFiltersById';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchType } from 'modules/search/types/SearchType';
import {
  getJobFamilyFacetData,
  getSearchPageIndex,
  getSearchQuery,
  getSearchRadius,
  getSearchSort,
  setSearchFilters,
  setSearchPageIndex,
  setSearchQuery,
  setSearchRadius,
  setSearchSort,
} from 'modules/search/zustand-stores/searchStore';
import { truncateToUTF8Bytes } from 'utils/string/truncateToUTF8Bytes';

type Args = {
  searchType: SearchType;
  searchParams: URLSearchParams;
  searchFacets: SearchFacet[];
};

export function assignSearchPageUrlParametersToZustandStore({
  searchType,
  searchParams,
  searchFacets,
}: Args) {
  /*
   * Query
   */
  const urlQuery = searchParams.get('q') || '';
  const truncatedUrlQuery = truncateToUTF8Bytes(urlQuery, 512);

  if (truncatedUrlQuery !== getSearchQuery()) {
    setSearchQuery(truncatedUrlQuery);
  }

  /*
   * Filters
   */

  // Object.fromEntries(searchParams.entries()) does not work properly with array params
  const parsedParams = queryString.parse(searchParams.toString()) as Record<
    string,
    string | string[]
  >;

  const filtersById: SearchFiltersById = {
    type: searchType,
    ...searchConvertFiltersByNameToFiltersById(searchFacets, parsedParams),
  };

  if (
    searchType === 'VOLOP' &&
    (!filtersById.actionTypeFacet ||
      (Array.isArray(filtersById.actionTypeFacet) &&
        filtersById.actionTypeFacet.length === 0))
  ) {
    filtersById.actionTypeFacet = ['VOLOP'];
  }

  if (parsedParams.synonymId) {
    const jobFamilyId = getJobFamilyFacetData()?.familyId;
    invariant(jobFamilyId, 'Missing jobFamilyId when filters to redux');
    filtersById.jobFamilyFacet = [jobFamilyId];
  }

  setSearchFilters(filtersById);

  /*
   * Sort
   */
  const urlSort =
    searchParams.get('sort') === 'newest' ? 'newest' : 'relevance';
  const reduxSort = getSearchSort() || 'relevance';

  if (urlSort !== reduxSort) {
    setSearchSort(urlSort);
  }

  /*
   * Page Index
   */
  const urlPageIndex = parseInt(searchParams.get('page') || '1', 10) - 1;

  if (urlPageIndex !== getSearchPageIndex()) {
    setSearchPageIndex(urlPageIndex);
  }

  /*
   * Radius
   */
  const urlRadius = searchParams.get('radius');
  const reduxRadius = getSearchRadius();

  if (reduxRadius && !urlRadius) setSearchRadius(undefined);
  if (urlRadius && urlRadius !== reduxRadius) setSearchRadius(urlRadius);
}
