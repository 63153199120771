import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { objectEmpty, setEquals } from 'utils/functional';

export function areFiltersDefault(
  facets: SearchFacet[],
  filtersById: SearchFiltersById,
) {
  // eslint-disable-next-line array-callback-return
  return facets.every((facet) => {
    const filter = filtersById[facet.id];

    switch (facet.type) {
      case 'array':
        return (
          !filter || (Array.isArray(filter) && setEquals(facet.options, filter))
        );
      case 'date':
        return (
          !filter ||
          // @ts-expect-error fix types
          objectEmpty(filter) ||
          (typeof filter === 'object' && Object.values(filter).every((v) => !v))
        );
      case 'string':
        return !filter;
    }
  });
}
