import { useSearchPageActions } from 'modules/search/hooks/useSearchPageActions';
import { useSearchPageFilterActions } from 'modules/search/hooks/useSearchPageFilterActions';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { updateSearchLocation } from 'modules/search/zustand-stores/searchStore';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

type Args = {
  searchFacets: SearchFacet[];
  isInSearchLandingPage: boolean;
};

export function useSearchFiltersSidebarActions({
  searchFacets,
  isInSearchLandingPage,
}: Args) {
  const {
    setSearchUrlSort,
    setSearchUrlRadius,
    setSearchUrlQueryOrLocationChanged,
    clearSearchUrl,
  } = useSearchPageActions({ isInSearchLandingPage });

  const {
    setSearchUrlFilter,
    selectSearchUrlFilterOption,
    selectAllSearchUrlFilterOptions,
    deselectSearchUrlFilterOption,
  } = useSearchPageFilterActions({ isInSearchLandingPage, searchFacets });

  const newActions = {
    // new
    dispatchSetSearchFilter: setSearchUrlFilter,
    dispatchSelectSearchFilterOption: selectSearchUrlFilterOption,
    dispatchDeselectSearchFilterOption: deselectSearchUrlFilterOption,
    dispatchSelectAllSearchFilterOptions: selectAllSearchUrlFilterOptions,
    dispatchSetSearchSort: setSearchUrlSort,
    dispatchClearAllFilters: clearSearchUrl,
    dispatchSetSearchRadius: setSearchUrlRadius,
  };

  return {
    ...newActions,
    dispatchSetSearchLocation: (
      searchLocation: SearchLocation | null | undefined,
    ) => {
      if (searchLocation) {
        deferAndStartRouteLoadingAnimation(() => {
          updateSearchLocation(searchLocation, true);
        });
      } else {
        deferAndStartRouteLoadingAnimation(() => {
          updateSearchLocation(EMPTY_SEARCH_LOCATION, true);
        });
      }

      setSearchUrlQueryOrLocationChanged({
        triggeredByRemoteLocationChange: Boolean(searchLocation?.isRemote),
      });
    },
  };
}
