import { Box } from 'components/Box';
import { colors } from 'theme/theme';
import { range } from 'utils/functional/array/range';

import { SearchLoadingSectionLine } from './SearchLoadingSectionLine';

export function SearchLoadingSectionSidebar() {
  return (
    <div>
      <Box borderBottom={`1px solid ${colors.selectionGrey}`} pb={32} pt={16}>
        <SearchLoadingSectionLine width={60} height={12} />
      </Box>
      {range(6).map((index) => (
        <Box
          py={27}
          key={index}
          borderBottom={`1px solid ${colors.selectionGrey}`}
        >
          <SearchLoadingSectionLine
            width={`${20 + ((index + 1) % 2) * 20}%`}
            height={9}
          />
        </Box>
      ))}
    </div>
  );
}
