import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const SearchResultsHitsHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SearchResultsFooter = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-top: 18px;

  > button,
  > a {
    align-self: center;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    justify-content: flex-end;

    > button,
    > a {
      align-self: flex-end;
    }
  }
`;
