import { SearchFiltersSidebar } from 'modules/search/components/FiltersSidebar/SearchFiltersSidebar';
import { SearchFiltersSidebarFacets } from 'modules/search/components/FiltersSidebar/SearchFiltersSidebarFacets';
import { useSearchFiltersSidebarCurrentSection } from 'modules/search/components/FiltersSidebar/useSearchFiltersSidebarCurrentSection';
import { SearchLoadingSectionSidebar } from 'modules/search/components/Loading/SearchLoadingSectionSidebar';
import { searchableFacetsByType } from 'modules/search/constants/searchableFacetsByType';
import { hasLocation } from 'modules/search/helpers/sidefilters';
import { SearchFacet } from 'modules/search/types/SearchFacet';

import { useSearchFiltersSidebarActions } from './useSearchFiltersSidebarActions';
import { useSearchFiltersSidebarState } from './useSearchFiltersSidebarState';

type Props = {
  initialized: boolean;
  isInSearchLandingPage: boolean;
  searchFacets: SearchFacet[];
};

export function SearchFiltersSidebarContainer({
  initialized,
  isInSearchLandingPage,
  searchFacets,
}: Props) {
  const {
    sidebarReady,
    filters,
    jobFamilyFacetData,
    searchLocation,
    searchRadius,
    defaultFiltersSet,
    keywords,
  } = useSearchFiltersSidebarState({ initialized, searchFacets });

  const {
    dispatchClearAllFilters,
    dispatchSetSearchRadius,
    dispatchSetSearchFilter,
    dispatchSelectSearchFilterOption,
    dispatchDeselectSearchFilterOption,
    dispatchSelectAllSearchFilterOptions,
    dispatchSetSearchLocation,
  } = useSearchFiltersSidebarActions({ searchFacets, isInSearchLandingPage });

  const facets = filters.type ? searchFacets : [];

  const searchableFacets = searchableFacetsByType[filters.type];

  const { toggleCurrentSection, currentSection } =
    useSearchFiltersSidebarCurrentSection({
      searchFacets: facets,
      filtersById: filters,
    });

  if (!sidebarReady) {
    return <SearchLoadingSectionSidebar />;
  }

  return (
    <SearchFiltersSidebar
      filtersById={filters}
      jobSynonym={jobFamilyFacetData?.jobSynonym}
      facetsToRender={facets}
      searchLocationActive={hasLocation(searchLocation)}
      searchLocation={searchLocation}
      clearAllFilters={dispatchClearAllFilters}
      setSearchLocation={dispatchSetSearchLocation}
      setSearchFilter={dispatchSetSearchFilter}
      deselectSearchFilterOption={dispatchDeselectSearchFilterOption}
      selectAllSearchFilterOptions={dispatchSelectAllSearchFilterOptions}
      defaultFiltersSet={defaultFiltersSet}
      toggleCurrentSection={toggleCurrentSection}
      facetsNode={
        <SearchFiltersSidebarFacets
          searchableFacets={searchableFacets}
          searchRadius={searchRadius}
          setSearchRadius={dispatchSetSearchRadius}
          selectSearchFilterOption={dispatchSelectSearchFilterOption}
          hideSearchLocationAndRadius={false}
          keywords={keywords || []}
          filtersById={filters}
          setSearchFilter={dispatchSetSearchFilter}
          searchLocation={searchLocation}
          searchLocationActive={hasLocation(searchLocation)}
          setSearchLocation={dispatchSetSearchLocation}
          toggleCurrentSection={toggleCurrentSection}
          currentSection={currentSection}
          deselectSearchFilterOption={dispatchDeselectSearchFilterOption}
          selectAllSearchFilterOptions={dispatchSelectAllSearchFilterOptions}
          facetsToRender={facets}
        />
      }
    />
  );
}
