import { sprintf } from 'sprintf-js';

import { Text } from 'components/Text/Text';
import { SearchLoadingSectionLine } from 'modules/search/components/Loading/SearchLoadingSectionLine';

type Props = {
  count: number | undefined;
};

export function SearchResultsHeaderCount({ count }: Props) {
  if (typeof count === 'undefined')
    return <SearchLoadingSectionLine width={64} height={20} />;

  return (
    <Text
      tag="h2"
      styleAs="body"
      display={['block', 'block', 'block', 'inline-block']}
      fontSize="16px!important"
      mb={0}
      data-qa-id="result-count"
      data-result-count={count}
    >
      {sprintf(getText('%(resultCount)s %(result)s'), {
        resultCount: count ? count.toLocaleString(CURRENT_LOCALE) : '0',
        result: count === 1 ? 'result' : 'results',
      })}
    </Text>
  );
}
