import styled from 'styled-components';

import { colors, cssBreakpoints, fontWeights } from 'theme/theme';

export const searchSidebarStickyBoxClassName = 'search-sidebar-sticky-box';

const searchSidebarFiltersDisplayShowTextVar =
  '--search-sidebar-filters-display-show-text';
const searchSidebarFiltersDisplayHideTextVar =
  '--search-sidebar-filters-display-hide-text';

export const SearchSidebarToggleHolder = styled.div`
  ${searchSidebarFiltersDisplayHideTextVar}: none;
  ${searchSidebarFiltersDisplayShowTextVar}: none;

  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    &:has(> label > input:checked) {
      ${searchSidebarFiltersDisplayHideTextVar}: flex;

      & + .${searchSidebarStickyBoxClassName} {
        display: flex;
      }
    }

    &:has(> label > input:not(:checked)) {
      & + .${searchSidebarStickyBoxClassName} {
        display: none;
      }

      ${searchSidebarFiltersDisplayShowTextVar}: flex;
    }
  }
`;

export const SearchSidebarStickyBox = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;

  @media screen and (max-width: ${cssBreakpoints.mdDown}) {
    position: relative !important;
    top: unset !important;
  }
`;

export const SearchSidebarMobileToggleLabel = styled.label`
  display: flex;
  padding: 16px 24px;
  font-size: 18px;
  font-weight: ${fontWeights.semiBold};
  color: ${colors.brandBlue};
  cursor: pointer;

  background: ${colors.backgroundGrey};
  border-bottom: 1px solid ${colors.elementGrey};
  user-select: none;

  input[type='checkbox'] {
    display: none;
  }

  @media screen and (min-width: ${cssBreakpoints.mdUp}) {
    display: none;
  }
`;

export const SearchSidebarMobileToggleLabelHideText = styled.span`
  gap: 8px;
  align-items: center;
  display: var(${searchSidebarFiltersDisplayHideTextVar});
`;

export const SearchSidebarMobileToggleLabelShowText = styled.span`
  gap: 8px;
  align-items: center;
  display: var(${searchSidebarFiltersDisplayShowTextVar});
`;
