import { sprintf } from 'sprintf-js';

import { SearchLocation } from 'modules/search/types/SearchLocation';

export function getSearchResultsEmptyMessage(
  resultType: string,
  query: string,
  searchLocation: SearchLocation | undefined,
) {
  if (query) {
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (searchLocation && searchLocation.text) {
      return sprintf(
        /* TRANSLATORS: search: no results message */
        getText('"%(query)s" near %(searchLocationText)s'),
        { resultType, query, searchLocationText: searchLocation.text },
      );
    }

    return sprintf(
      /* TRANSLATORS: search: no results message */
      getText('"%(query)s"'),
      { resultType, query },
    );
  }

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (searchLocation && searchLocation.text) {
    return sprintf(
      /* TRANSLATORS: search: no results message */
      getText('All %(resultType)s near %(searchLocationText)s'),
      { resultType, searchLocationText: searchLocation.text },
    );
  }

  return sprintf(
    /* TRANSLATORS: search: no results message */
    getText('All %(resultType)s'),
    { resultType },
  );
}
