import { useCallback } from 'react';

import { useLatest } from 'hooks/useLatest';
import { searchGetTrackingData } from 'modules/search/helpers/analytics/searchGetTrackingData';

type Args = {
  searchFrom?: string;
};

export function useSearchAnalytics({ searchFrom }: Args) {
  // Don't trigger analytics change just because the searchFrom value changed
  const searchFromRef = useLatest(searchFrom);

  const getSearchTrackingData = useCallback(
    () => searchGetTrackingData({ searchFrom: searchFromRef.current }),
    [searchFromRef],
  );

  return { getSearchTrackingData };
}
