import { ReactNode, useCallback } from 'react';

import { userDashboardApiUpdateApplicantProfile } from 'modules/userDashboard/api/applicantProfile/userDashboardApiUpdateApplicantProfile';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { LocalProductNotificationType } from 'types/LocalNotification/LocalProductNotificationType';
import { clearAdStore } from 'zustand-stores/adStore';
import { dismissLocalNotification } from 'zustand-stores/localNotificationsStore';

function ResumeDatabaseResponseContainer({
  children,
}: {
  children: (arg0: { dismiss: () => void }) => ReactNode;
}) {
  const { user } = useUserEnvironment();

  const dismiss = useCallback(() => {
    if (user) {
      userDashboardApiUpdateApplicantProfile({ isRecruitable: false });
    }

    // TODO why not dismiss when the user is not logged in?
    dismissLocalNotification(LocalProductNotificationType.UPLOAD_RESUME);
    clearAdStore();
  }, [user]);

  return <>{children({ dismiss })}</>;
}

// eslint-disable-next-line import/no-default-export
export default ResumeDatabaseResponseContainer;
