import { ApiUserEnvironment } from 'api/userEnvironment/types/ApiUserEnvironment';
import { patchJson } from 'utils/http/patchJson';

import { UserDashboardApiApplicantProfile } from './types/UserDashboardApiApplicantProfile';

type ApiResponse = {
  applicantProfile: UserDashboardApiApplicantProfile;
  userEnvironment: ApiUserEnvironment;
};

export function userDashboardApiUpdateApplicantProfile(
  values: Partial<UserDashboardApiApplicantProfile>,
): Promise<ApiResponse> {
  return patchJson('/data/userdashboard/applicant-profile/profile', values);
}
