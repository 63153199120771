import { SearchFacetId } from 'modules/search/types/SearchFacet';
import { SearchType } from 'modules/search/types/SearchType';

export const searchableFacetsByType = {
  JOB: ['orgAreasOfFocusFacet', 'jobKeywordsFacet'],
  INTERNSHIP: ['orgAreasOfFocusFacet', 'internshipKeywordsFacet'],
  VOLOP: ['volopAreasOfFocusFacet', 'volopKeywordsFacet'],
  EVENT: ['volopAreasOfFocusFacet', 'volopKeywordsFacet'],
  ORG: ['orgAreasOfFocusFacet'],
} satisfies Record<SearchType, SearchFacetId[]>;
