import styled from 'styled-components';

import { colors, cssBreakpoints } from 'theme/theme';

export const SearchResultsHeaderHolder = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-bottom: 24px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${colors.selectionGrey};
`;

export const SearchResultsHeaderLeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const SearchResultsHeaderLeftSideSort = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;

export const SearchResultsHeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SearchResultsHeaderPostAListingHolder = styled.div`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }
`;
