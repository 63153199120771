import styled from 'styled-components';

import { cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';

export const SearchBodyHolder = styled.div`
  position: relative;
  max-width: ${maxWidth[3]}px;
  margin: 0 auto;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    display: flex;
    margin-top: 24px;
    padding-bottom: 48px;
    padding-top: 24px;
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    background: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    border-radius: 12px;
  }
`;

export const SearchBodySidebar = styled.aside`
  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    width: 100%;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    /* Use flex to fix sticky sidebar lag, avoiding click intercepted during tests */
    display: flex;
    flex-direction: column;

    width: 33.3%;
    padding-left: ${gutterWidth}px;
    padding-right: ${gutterWidth}px;
  }
`;
