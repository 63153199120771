import { trackEvent } from './trackEvent';

const EVENTS: Record<string, unknown> = {};

export function trackEventOnce(
  eventName: string,
  properties: Record<string, unknown> = {},
) {
  if (typeof window === 'undefined') return;

  const key = `${eventName}${JSON.stringify(properties)}${
    window.location.pathname
  }${window.location.search}`;

  if (!EVENTS[key]) {
    EVENTS[key] = true;
    trackEvent(eventName, properties);
  }
}
