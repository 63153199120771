import { useCallback, useMemo } from 'react';
import deepEqual from 'react-fast-compare';

import { searchGetTrackingData } from 'modules/search/helpers/analytics/searchGetTrackingData';
import { SearchFacet, SearchFacetId } from 'modules/search/types/SearchFacet';
import {
  getSearchFiltersById,
  selectedOption,
} from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { keyBy } from 'utils/functional/array/keyBy';

import { useSearchPageActionsHelpers } from './useSearchPageActionsHelpers';

type Args = {
  isInSearchLandingPage: boolean;
  searchFacets: SearchFacet[];
};

/**
 * Search Page actions that require awareness of search facets
 */
export function useSearchPageFilterActions({
  isInSearchLandingPage,
  searchFacets,
}: Args) {
  const { updateQueryParams } = useSearchPageActionsHelpers({
    isInSearchLandingPage,
  });

  const searchFacetsById = useMemo(
    () => keyBy(searchFacets, 'id'),
    [searchFacets],
  );

  const isValueEqualToCurrentFilterValue = useCallback(
    (
      facetId: SearchFacetId,
      value: string | string[] | Record<string, unknown> | null | undefined,
    ) => {
      const currentValue = getSearchFiltersById()[facetId];
      return deepEqual(currentValue, value);
    },
    [],
  );

  const setSearchUrlFilter = useCallback(
    (
      facetId: SearchFacetId,
      value: string | string[] | Record<string, unknown> | null | undefined,
    ) => {
      if (isValueEqualToCurrentFilterValue(facetId, value)) return;

      const facet = searchFacetsById[facetId];
      updateQueryParams({ page: undefined, [facet.name as string]: value });

      trackEvent('Changed Search Filter', {
        ...searchGetTrackingData({}),
        filter_name: facetId,
        filter_value: selectedOption(facetId),
        selected_value: Array.isArray(value) ? value[0] : value,
      });
    },
    [isValueEqualToCurrentFilterValue, searchFacetsById, updateQueryParams],
  );

  const selectSearchUrlFilterOption = useCallback(
    (facetId: SearchFacetId, optionToSelect: string) => {
      const facet = searchFacetsById[facetId];
      const previousSelectedOptions =
        (getSearchFiltersById()[facet.id] as string[] | undefined) || [];
      const selectedOptions = [...previousSelectedOptions, optionToSelect];

      let newValue;

      if (
        facetId === 'actionTypeFacet' &&
        selectedOptions.length === facet.options.length
      ) {
        newValue = facet.options;
      } else if (selectedOptions.length !== facet.options.length) {
        newValue = selectedOptions;
      }

      if (isValueEqualToCurrentFilterValue(facetId, newValue)) return;

      updateQueryParams({
        page: undefined,
        [facet.name as string]: newValue,
      });

      trackEvent('Changed Search Filter', {
        ...searchGetTrackingData({}),
        filter_name: facetId,
        filter_value: selectedOption(facetId),
        selected_value: optionToSelect,
      });
    },
    [isValueEqualToCurrentFilterValue, searchFacetsById, updateQueryParams],
  );

  const selectAllSearchUrlFilterOptions = useCallback(
    (filterKey: SearchFacetId) => {
      const facet = searchFacetsById[filterKey];

      // Different from the other filters, if we set `actionTypeFacet` to `undefined`
      // the code will assume that we mean `VOLOP`, not `ALL`
      const newValue =
        filterKey === 'actionTypeFacet' ? facet.options : undefined;

      if (isValueEqualToCurrentFilterValue(filterKey, newValue)) return;

      updateQueryParams({ [facet.name as string]: newValue });

      trackEvent('Changed Search Filter', {
        ...searchGetTrackingData({}),
        filter_name: filterKey,
        filter_value: selectedOption(filterKey),
        selected_value: 'ALL',
      });
    },
    [isValueEqualToCurrentFilterValue, searchFacetsById, updateQueryParams],
  );

  const deselectSearchUrlFilterOption = useCallback(
    (facetId: SearchFacetId, optionToDeselect: string) => {
      const facet = searchFacetsById[facetId];
      const previousSelectedOptions =
        (getSearchFiltersById()[facet.id] as string[] | undefined) || [];
      const selectedOptions = previousSelectedOptions.filter(
        (option) => option !== optionToDeselect,
      );

      if (isValueEqualToCurrentFilterValue(facetId, selectedOptions)) return;

      updateQueryParams({
        page: undefined,
        [facet.name as string]: selectedOptions,
      });

      trackEvent('Changed Search Filter', {
        ...searchGetTrackingData({}),
        filter_name: facetId,
        filter_value: selectedOption(facetId),
        deselected_value: optionToDeselect,
      });
    },
    [isValueEqualToCurrentFilterValue, searchFacetsById, updateQueryParams],
  );

  return {
    setSearchUrlFilter,
    selectSearchUrlFilterOption,
    selectAllSearchUrlFilterOptions,
    deselectSearchUrlFilterOption,
  };
}
