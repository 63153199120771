import type { SearchResponse } from '@algolia/client-search';

import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { PostListingLink } from 'components/Link/PostListingLink';
import { SortMenu } from 'components/SortMenu';
import { Text } from 'components/Text/Text';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { SaveSearchButton } from 'modules/search/components/SaveSearch/SaveSearchButton';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { SearchSortType } from 'modules/search/types/SearchSortType';
import { SearchType } from 'modules/search/types/SearchType';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import {
  SearchResultsHeaderHolder,
  SearchResultsHeaderLeftSide,
  SearchResultsHeaderLeftSideSort,
  SearchResultsHeaderPostAListingHolder,
  SearchResultsHeaderRightSide,
} from './SearchResultsHeader.styled';
import { SearchResultsHeaderCount } from './SearchResultsHeaderCount';

type Props = {
  results: SearchResponse<SearchAlgoliaHit> | undefined;
  isSearching: boolean;
  query: string;
  searchType: SearchType;
  sort: SearchSortType;
  saveSearch: () => void;
  saveableSearch: boolean;
  onChangeSort: (sort: SearchSortType) => void;
  searchFacets: SearchFacet[];
};

export function SearchResultsHeader({
  results,
  isSearching,
  query,
  searchType,
  sort,
  saveSearch,
  saveableSearch,
  onChangeSort,
  searchFacets,
}: Props) {
  return (
    <SearchResultsHeaderHolder>
      <SearchResultsHeaderLeftSide>
        <SearchResultsHeaderCount
          count={!isSearching ? results?.nbHits : undefined}
        />

        {query && (
          <SearchResultsHeaderLeftSideSort>
            <SortMenu
              value={sort}
              options={[
                { name: getText('Best Match'), param: 'relevance' },
                { name: getText('Newest'), param: 'newest' },
              ]}
              onChange={onChangeSort}
            />
          </SearchResultsHeaderLeftSideSort>
        )}
      </SearchResultsHeaderLeftSide>

      {
        // Wait for the search to happen to avoid these buttons being unresponsive due to HIGH CPU usage
        !isSearching && results && (
          <SearchResultsHeaderRightSide>
            {searchType === 'JOB' && (
              <SearchResultsHeaderPostAListingHolder>
                <PostListingLink
                  onClick={() => {
                    trackClicked('Post A Listing', {
                      context: 'Main Search Results',
                    });
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Box mt="1px" display="inline-flex">
                      <Icon name="pin" size={20} />
                    </Box>
                    <Text ml="0.6em" mr="0.3em" fontSize="16px">
                      {/* TRANSLATORS: Post a Job in search results */}
                      {getText('Post a Job')}
                    </Text>
                  </Box>
                </PostListingLink>
              </SearchResultsHeaderPostAListingHolder>
            )}

            {saveableSearch && (
              <SaveSearchButton
                saveSearch={saveSearch}
                searchFacets={searchFacets}
              />
            )}
          </SearchResultsHeaderRightSide>
        )
      }
    </SearchResultsHeaderHolder>
  );
}
