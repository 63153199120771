import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { gutterWidth } from 'theme/theme';

import { SearchBodyHolder, SearchBodySidebar } from './SearchBody.styled';

type Props = {
  sidebar: ReactNode;
  children: ReactNode;
};

export function SearchBody({ sidebar, children }: Props) {
  return (
    <SearchBodyHolder data-qa-id="search-results-body">
      <SearchBodySidebar data-qa-id="search-sidebar">
        {sidebar}
      </SearchBodySidebar>

      <Box
        width={[1, 1, 1, 2 / 3]}
        px={[gutterWidth / 2, gutterWidth / 2, gutterWidth / 2, gutterWidth]}
        py={[gutterWidth / 2, gutterWidth / 2, gutterWidth / 2, 0]}
        data-qa-id="search-results"
      >
        {children}
      </Box>
    </SearchBodyHolder>
  );
}
